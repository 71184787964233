import React, { useState } from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Divider,
  notification,
} from "antd"
import {
  CheckCircleTwoTone,
  CheckOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import useBreakpoint from "../utils/useBreakpoint"
import Disa1x from '../images/disa/disa-logo.jpg'
import Disa2x from '../images/disa/disa-logo@2x.jpg'
import Disa3x from '../images/disa/disa-logo@3x.jpg'

import Rotech1x from '../images/rotech/rotech-logo.jpg'
import Rotech2x from '../images/rotech/rotech-logo@2x.jpg'
import Rotech3x from '../images/rotech/rotech-logo@3x.jpg'


const API_URL = "https://prod.magnusmonitors.com/api/web/contact_form"

const REGIONS = [
  "UK & Ireland",
  "Europe",
  "North America",
  "Middle East & Asia",
  "Others",
]

const INDUSTRIES = [
  "Fuel Oil",
  "Waste Oil",
  "Lubricants",
  "Dairy",
  "Waste Management",
  "Water Management",
  "Chemicals",
  "Other",
]

const IndexPage = () => {
  const [form] = Form.useForm()
  const {isSmallScreen} = useBreakpoint()
  const [hasContacted, setContactedState] = useState(false)
  const [isContacting, setContactingState] = useState(false)
  const handleFormFillFinished = async data => {
    if (hasContacted) return
    setContactingState(true)
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    setContactingState(false)
    if (response.ok) {
      notification.open({
        message: "Contact request sent!",
        description: "Our executives will be in touch with you shortly!",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      })
      setContactedState(true)
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: "Contact Us",
        // string - required - Type of interaction (e.g. 'play')
        action: "Click"
      })
    } else {
      notification.open({
        message: "Oops! Something went wrong!",
        description: "Please try again!",
        icon: <ExclamationCircleOutlined style={{ color: "#cf1322" }} />,
      })
    }
  }
  return (
    <Layout>
      <SEO title="Contact" />
      <section className="contact-us-form-section">
          <h1>contact us</h1>
        <Row gutter={[20, 0]}>
          <Col span={isSmallScreen ? 24 : 12}>
              <Form form={form} onFinish={handleFormFillFinished}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email address.",
                    },
                    { type: "email", message: "Invalid email address." },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Please enter a name" }]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
                <Form.Item name="phone">
                  <Input placeholder="Phone Number" />
                </Form.Item>
                <Form.Item name="company_name">
                  <Input placeholder="Company Name" />
                </Form.Item>
                <Form.Item name="region">
                  <Select placeholder="Region">
                    {REGIONS.map(region => (
                      <Select.Option
                        className="contact-us-select-options"
                        value={region}
                      >
                        {region}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="industry">
                  <Select placeholder="Industry Type">
                    {INDUSTRIES.map(industry => (
                      <Select.Option
                        className="contact-us-select-options"
                        value={industry}
                      >
                        {industry}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="reason">
                  <Input.TextArea placeholder="Reason for Interest" />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={isContacting}
                    className="submit-button"
                    block
                    type="primary"
                    htmlType="submit"
                    icon={hasContacted ? <CheckOutlined /> : null}
                    style={
                      hasContacted ? { backgroundColor: "#52c41a" } : undefined
                    }
                  >
                    {hasContacted ? "Done" : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
          </Col>
          <Col span={isSmallScreen ? 24 : 3} style={{ display: "flex", justifyContent: "center" }}>
            <Divider
              orientation="center"
              type={isSmallScreen ? "horizontal" : "vertical"}
              style={{ height: isSmallScreen ? undefined : "100%", width: isSmallScreen ? "100%" : undefined, backgroundColor: "#acacac" }}
            />
          </Col>
          <Col
            span={isSmallScreen ? 24 : 8}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
              <div>
                <div className="info-title">
                  <h2>Address</h2>
                </div>
                <p>
                  Magnus Monitors (Eirscope),
                  <br />
                  Unit 39, Briarhill Business Park,
                  <br />
                  Ballybrit, Galway,
                  <br />
                  Ireland.
                </p>
              </div>
              <div>
                <div className="info-title">
                  <h2>General Enquiries</h2>
                </div>
                <p>hello@magnusmonitors.com</p>
              </div>
              <div>
                <div className="info-title">
                  <h2>Sales Enquiries (Trade Only)</h2>
                </div>
                <p>sales@magnusmonitors.com</p>
              </div>
              <div>
                <div className="info-title">
                  <h2>Phone</h2>
                </div>
                <p>
                  Head Office
                  <br />
                  +353 91 392747
                </p>
              </div>
          </Col>
        </Row>
        <div className="distributors-container">
          <Row>
             <h1>OUR DISTRIBUTORS</h1>
          </Row>
          <Row>
            <a href="https://rotechshop.ie/product/magnus-tank-gauge/" target="_blank">
              <Col className="distributor-card">
                <div className="logo-container"><img src={Rotech2x} srcSet={`${Rotech1x} 1x, ${Rotech2x} 2x, ${Rotech3x} 3x`} alt="logo"/></div>
                <div>info@rotech.ie</div>
                <div>+353 53 913 5165</div>
                <div>Hilltown, Killinick,<br/>Co. Wexford, Y35 DCF8,<br/>Ireland.</div>
              </Col>
            </a>
            <a href="https://www.di-sa.net/magnus-monitors" target="_blank">
              <Col className="distributor-card">
                <div className="logo-container"><img src={Disa2x} srcSet={`${Disa1x} 1x, ${Disa2x} 2x, ${Disa3x} 3x`} alt="logo"/></div>
                <div>magnusafrica@di-sa.net</div>
                <div>+27 82 211 6613</div>
                <div>Magnus Monitors Africa (DISA),<br/>7 South Road, Mtunzini,<br/>South Africa.</div>
              </Col>
            </a>
          </Row>
        </div>
        <article className="contact-us-about-section">
          <h1>who we are</h1>
          <p>
            Our aim is to lead in the design of a highly accurate end to end
            monitoring solution using superior technology to allow you to run
            your business effectively and efficiently enhancing both you and
            your customers and partners experience and engagement.
          </p>
          <p>
            Based in the West of Ireland, the Magnus team combines technical
            expertise in both advanced hardware and enterprise software with
            many years experience within the industry which has allowed us to
            develop this exciting new technology to help improve the way your
            business operates day to day.
          </p>
          </article>
      </section>
    </Layout>
  )
}

export default IndexPage
